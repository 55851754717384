import React, { useState } from "react";
import useOnScreen from "../useOnScreen";
import "./projectCard.css";

function ProjectTemplate(props) {
  const [onScreenChange, isVisible] = useOnScreen({
    root: null,
    // rootMargine: "-30px",
    rootMargine: "0px",
    threshold: 0.2,
  });

  const [isActive, setIsActive] = useState(false);

  const handleTouchStart = () => {
    setIsActive(true);
  };

  const handleTouchEnd = () => {
    setIsActive(false);
  };

  return (
    <div
      ref={onScreenChange}
      className={
        isVisible
          ? " projectOnScreen projectContainer"
          : "projectOffScreen projectContainer"
      }
      onMouseEnter={handleTouchStart}
      onMouseLeave={handleTouchEnd}
    >
      <div className="cardContainer">
        <div className="cardBackground">
          <img className="cardImage " src={props.imgTitle} />
          {/* <div className="cardContainer">
          <div className="card">
            <div className="topFace">
              <div className="content">
                <h2 className="projectH">{props.title}</h2>
              </div>
            </div>
            <div className="face face2">
              <div className="content">
                <p className="projectP">{props.description}</p>
                <a href={props.link} type="button">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div> */}
        </div>
        <div className="cardLayer">
          <hr className="line"></hr>
          {isActive ? (
            <a href={props.link}>
              <h2 className="">{props.title}</h2>
            </a>
          ) : (
            <a>
              <h2 className="">{props.title}</h2>
            </a>
          )}
          <p>{props.description}</p>
          {isActive ? <a href={props.link}>See More</a> : <a>See More</a>}
          <hr className="line"></hr>
        </div>
      </div>
    </div>
  );
}

export default ProjectTemplate;
