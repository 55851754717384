import React, { useEffect, useState, useRef } from "react";
// import * as THREE from "three";
// import { Canvas, useFrame } from "@react-three/fiber";
// import {
//   EffectComposer,
//   DepthOfField,
//   Bloom,
//   Noise,
//   Vignette,
//   Select,
//   Selection,
//   SelectiveBloom,
// } from "@react-three/postprocessing";
// import { BlurPass, Resizer, KernelSize } from "postprocessing";
// import {
//   OrbitControls,
//   // Sphere,
//   Reflector,
//   // MeshReflectorMaterial,
//   useTexture,
// } from "@react-three/drei";
import { Parallax } from "react-scroll-parallax";
import DivBorder from "../DivBorder";

// import WhitePorsche from "../WhitePorsche";

import "./personal.css";
// import { PointLight } from "three";

import ProjectTemplate from "../ProjectCard/ProjectCard.jsx";
import LeftInText from "../LeftInText";

// import Tricity from "../../assets/Tricity.jpg";
import TeslaUI from "../../assets/TeslaUI.jpg";
// import Tracker from "../../assets/ProjectTracker.jpg";
import CanadaGoose from "../../assets/canadaGoose.png";
import EFHE from "../../assets/EFHE.jpg";
import birnam from "../../assets/Birnam.jpg";

// function Ground(props) {
//   const [floor, normal] = useTexture([
//     process.env.PUBLIC_URL + "/SurfaceImperfections003_1K_var1.jpg",
//     // process.env.PUBLIC_URL + "/SurfaceImperfections003_1K_Normal.jpg",
//   ]);
//   return (
//     <Reflector resolution={500} args={[8, 8]} {...props}>
//       {(Material, props) => (
//         <Material
//           color="#f0f0f0"
//           metalness={0}
//           roughnessMap={floor}
//           normalMap={normal}
//           normalScale={[2, 2]}
//           {...props}
//         />
//       )}
//     </Reflector>
//   );
// }

// const startX = -4;
// const rotateX = -3;
// const startZ = 0;
// const rotateZ = 1;

const startX = -4;
const rotateX = -3;
const startZ = 0;
const rotateZ = -4;

const lightOnPP = 0.3;
const movePP = 0.7;

function Personal() {
  const bodyRef = useRef();
  // const [ref, bounds] = useMeasure();
  // const [aniState, setAni] = useState(0);
  // const [onScreenChange, isVisible] = useOnScreen({
  //   root: null,
  //   rootMargine: "-30px",
  //   threshold: 0,
  // });

  // const [textOnScreen, isVisible] = useOnScreen({
  //   root: null,
  //   rootMargine: "-30px",
  //   threshold: 0,
  // });

  const [scroll, setScroll] = useState(0);
  const lightMesh = useRef();
  const spotLightRef1 = useRef();
  const spotLightRef2 = useRef();
  const lightIntensityMulti = useRef(1);

  // function Rotate({ v = new THREE.Vector3() }) {
  //   return useFrame((state) => {
  //     if (scroll >= 0 && scroll <= 0.5) {
  //       lightIntensityMulti.current = scroll * 2 + 0.1;
  //     }
  //   });
  // }

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   const divStartPos = position + bodyRef.current.getBoundingClientRect().top;
  //   // console.log(
  //   //   "scroll: " +
  //   //     (position - divStartPos + window.innerHeight) /
  //   //       bodyRef.current.getBoundingClientRect().height
  //   // );
  //   setScroll(
  //     (position - divStartPos + window.innerHeight) /
  //       bodyRef.current.getBoundingClientRect().height
  //   );
  // };

  // useEffect(() => {
  //   ref.current = bodyRef.current;
  //   onScreenChange.current = bodyRef.current;
  // }, [bodyRef.current]);

  //This is the piece that adds the scroll stuff
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isVisible) {
  //     setAni(1);
  //     console.log("Turning on headlights");
  //     setTimeout(() => {
  //       setAni(2);
  //       // move camera to left
  //       console.log("Move camera");
  //       setTimeout(() => {
  //         setAni(3);
  //         // move directional lights
  //         console.log("Move lights");
  //       }, 1000);
  //     }, 1000);
  //     // lerp colour
  //     //set interplate then
  //   }
  // }, [isVisible]);

  // const targetObject = new THREE.Object3D(0, 0, 100);

  const txtRepeats = 10;
  const txtRows = 5;
  const txtCols = 2;

  return (
    <>
      <section ref={bodyRef} id="personalContainer">
        <div className="curveTop">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <section className="scrolling-lines back">
          {[...Array(txtRows)].map((e, i) => (
            <div className="scrolling-line-container scrolling-line-container-back">
              {[...Array(txtCols)].map((e, i) => (
                <div className="scrolling-line scrolling-line-back">
                  {[...Array(txtRepeats)].map((e, i) => (
                    <span>PROJECTS</span>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </section>
        <DivBorder>
          <section className="scrolling-lines front">
            {[...Array(txtRows)].map((e, i) => (
              <div className="scrolling-line-container scrolling-line-container-front">
                {[...Array(txtCols)].map((e, i) => (
                  <div className="scrolling-line scrolling-line-front">
                    {[...Array(txtRepeats)].map((e, i) => (
                      <span>PROJECTS</span>
                    ))}
                  </div>
                ))}
              </div>
            ))}

            <DivBorder>
              <></>
            </DivBorder>
          </section>
        </DivBorder>

        <div id="personal">
          <div className="personalTitlePanel">
            <LeftInText>
              <h1 className="textPanel">Coding Projects</h1>
            </LeftInText>
            <div className="carPanel">
              <Parallax translateY={["100px", "-50px"]}>
                {/* <img src={require("../../assets/PorscheNB.png")} /> */}
                <img
                  src={require("../../assets/proteins/BinfTitleCard.jpeg")}
                />
              </Parallax>
            </div>
          </div>
          <div className="card-container">
            <div className="personalProjectContainer inner-card-container">
              <ProjectTemplate
                title="Canada Goose Authenticator"
                link="https://canadagooseauthenticator.netlify.app/"
                description="Have you ever gone to buy a second-hand Canada Goose jacket, but questioned the legitimacy of the product? Well now, with a simple photo of a badge, you can verify the authenticity of your jacket."
                imgTitle={CanadaGoose}
              />
              <ProjectTemplate
                title="Tesla UI Clone"
                link="https://model3ui.netlify.app/"
                description="Having been a huge Tesla fan for a while, I decided to take a crack at cloning the Tesla Model 3 UI system in react."
                imgTitle={TeslaUI}
              />
              <ProjectTemplate
                title="Birnam Wood"
                link="https://2021.igem.org/Team:Calgary/Birnam_Wood"
                description="Assess how fast a protein does its jobs can be a complex task. Birnam wood, a random forest machine learning model, gives researchers a simplified method of assessing protein reaction rates."
                imgTitle={birnam}
              />
              <ProjectTemplate
                title="EFHE"
                link="https://2021.igem.org/Team:Calgary/EFHE"
                description="Understanding what protein structures do can be challenging, which is where EFHE comes in, an artificial neural network which takes in protein sequences and identifies which parts are capable of binding ions. "
                imgTitle={EFHE}
              />
              {/* <ProjectTemplate
              title="Tricity Website"
              link="https://tricity.netlify.app/"
              description="In order to test our web development skills, a group of friends and I build a Proof of Concept React website for the Japanese restaurant, Tricity."
              imgTitle={Tricity}
            /> */}
              {/* <ProjectTemplate
              title="Project Tracker"
              link="#"
              description="Sometimes you just want a simple way to quickly track how much time you've spent on different activities. With this stopwatch/project tracker, you can easily keep track of your time investment across multiple projects. (Project not currently live)"
              imgTitle={Tracker}
            /> */}
            </div>
          </div>
        </div>
        <Parallax translateY={["101px", "83px"]}>
          <div className="curveBottom">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </Parallax>
      </section>
    </>
  );
}

export default Personal;
